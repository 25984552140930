<template>
  <div>
    <KTPortlet title="Services">
      <template v-slot:toolbar>
        <div class="dropdown dropdown-inline">
          <button
            @click="addService"
            type="button"
            class="btn btn-clean btn-sm btn-icon btn-icon-md"
          >
            <i class="flaticon2-plus"></i>
          </button>
        </div>
      </template>
      <template slot="body">
        <Loader v-if="services === null" :inContent="true">Loading...</Loader>
        <b-table-simple v-else responsive>
          <b-thead>
            <b-tr>
              <b-th sticky-column>Name</b-th>
              <b-th>Status</b-th>
              <b-th>Type</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="services.length == 0">
              <b-td :colspan="3" style="text-align: center;"
                >No services setup</b-td
              >
            </b-tr>
            <template v-else>
              <b-tr v-for="service in services" v-bind:key="service.id">
                <b-th sticky-column>{{ service.name }}</b-th>
                <b-th>
                  <v-chip
                    class="ma-2"
                    :style="
                      `background: var(--${
                        service.active ? 'success' : 'danger'
                      }); color: #fff;`
                    "
                    >{{ service.active ? "Enabled" : "Disabled" }}</v-chip
                  >
                </b-th>
                <b-th>{{ getTypeName(service.type) }}</b-th>
                <b-td style="width: 50px">
                  <b-button
                    style="color: #FFFFFF;"
                    variant="info"
                    @click="viewEdit(service)"
                    >View/Edit</b-button
                  >
                  <!-- <router-link :to="`/Networks/Syslog/${syslog.key}`" v-if="!syslog.selected">
                  <v-icon>flaticon-eye</v-icon>
                  </router-link>-->
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </template>
    </KTPortlet>
    <YSIDEBAR ref="sidebar">
      <template
        slot="header"
        v-if="!$tools.isNullOrUndefined(editDeviceObject)"
      >
        <h3 style="padding: 0 20px 0 20px;">
          Configure {{ (editDeviceRefObject || {}).name || "New service" }}
        </h3>
      </template>
      <Loader v-if="editDeviceObjectSaving === true" :inContent="true"
        >Loading...</Loader
      >
      <v-form
        ref="form"
        v-model="editDeviceObjectValid"
        lazy-validation
        v-else-if="!$tools.isNullOrUndefined(editDeviceObject)"
      >
        <div style="padding: 20px;">
          <v-text-field
            v-model="editDeviceObject.name"
            type="text"
            label="Name"
            jrules="editDeviceRules"
            :required="true"
          ></v-text-field>
          <v-checkbox
            v-model="editDeviceObject.active"
            label="Active"
            mhint="Adopt Device"
            :persistent-hint="true"
          ></v-checkbox>
          <v-text-field
            v-if="!$tools.isNullOrUndefined(editDeviceObject.type)"
            v-model="editDeviceObject.type"
            type="text"
            label="Type"
            :readonly="true"
            jrules="editDeviceRules"
          ></v-text-field>
          <b-select
            v-else
            @change="typeChange()"
            :options="serviceTypes"
            item-text="text"
            item-value="value"
            :required="true"
            v-model="editDeviceObject.type"
            label="Type"
          ></b-select>
          <template
            v-if="
              !$tools.isNullOrUndefined(editDeviceObject) &&
                !$tools.isNullOrUndefined(editDeviceObject.type) &&
                !$tools.isNullOrUndefined(editDeviceObject.config)
            "
          >
            <div v-for="field of optionFields" v-bind:key="field.id">
              <component
                @change="fieldChange()"
                @input="fieldChange()"
                @blur="fieldChange()"
                v-bind="field"
                v-bind:is="field.is"
                v-model="
                  editDeviceObject[
                    field.credential === true ? 'credentials' : 'config'
                  ][field.id]
                "
              ></component>
              <v-alert
                v-if="
                  !$tools.isNullOrUndefined(field.info) &&
                    field.info.show ==
                      editDeviceObject[
                        field.credential === true ? 'credentials' : 'config'
                      ][field.id]
                "
                :type="field.info.type || 'info'"
                :class="`bg-${field.info.type || 'info'}`"
                v-html="field.info.text.join('<br />')"
              ></v-alert>
            </div>
          </template>
          <div style="height: 50px;"></div>
          <b-button
            style="margin-right: 20px; color: #FFFFFF;"
            :disabled="!editDeviceObjectValid"
            @click="saveEditDevice"
            :variant="editDeviceObjectValid ? 'success' : 'warning'"
            >SAVE</b-button
          >
        </div>
      </v-form>
    </YSIDEBAR>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import Y_SIDEBAR from "../../partials/layout/QuickPanelBase";
//import Swal from "sweetalert2";
import TLib from "./lib";
import MOMENT from "moment";
import "moment-timezone";

export default {
  name: "Services",
  components: {
    KTPortlet,
    Loader,
    YSIDEBAR: Y_SIDEBAR
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        // $route.query.logId
        /*for (let syslog of this.$data.syslogs) {
          syslog.selected = this.$route.params.logId === syslog.key;
        }*/
      }
    },
    editDeviceObject: {
      deep: true,
      handler() {
        this.$data.optionFields = this.getOptionFields();
        // $route.query.logId
        /*for (let syslog of this.$data.syslogs) {
          syslog.selected = this.$route.params.logId === syslog.key;
        }*/
      }
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      services: null,

      editDeviceObject: null,
      editDeviceRefObject: null,
      editDeviceObjectValid: true,
      editDeviceObjectSaving: false,
      optionFields: [],
      serviceTypes: [
        {
          text: "Select a type",
          value: null,
          fields: []
        },
        {
          text: "UNMS",
          value: "unms",
          fields: [
            {
              id: "hostname",
              label: "Hostname of server",
              is: "v-text-field",
              type: "text",
              required: true
            },
            {
              id: "apiKey",
              label: "API Key",
              is: "v-text-field",
              type: "password",
              credential: true
            },
            {
              id: "tz",
              label: "Server Timezone",
              is: "b-select",
              options: MOMENT.tz.names()
            },
            {
              id: "dfs-rebooter",
              label: "Auto reboot devices after a DFS hit",
              is: "v-checkbox",
              info: {
                text: [
                  "Create a UNMS user with the email:",
                  "unms-client@ninja.bettercorp.co.za",
                  "",
                  "This allows us to receive the DFS hit emails as they are not exposed by the UNMS API."
                ],
                show: true
              }
            },
            {
              if: {
                "config.dfs-rebooter": true
              },
              id: "dfs-rebooter-time",
              label: "Always run",
              default: true,
              off: false,
              is: "v-checkbox"
            },
            {
              if: {
                "config.dfs-rebooter": true,
                "config.dfs-rebooter-time": false
              },
              id: "dfs-rebooter-time-from",
              label: "Run from",
              off: null,
              is: "b-time"
            },
            {
              id: "pull-devices",
              label: "Pull devices from UNMS",
              is: "v-checkbox"
            },
            {
              id: "sync-devices",
              label: "Force one way changes to UNMS",
              is: "v-checkbox"
            }
          ]
        },
        {
          text: "SLACK",
          value: "slack",
          fields: [
            {
              id: "key1",
              label: "Webhook API key 1",
              is: "v-text-field",
              type: "password",
              required: true,
              credential: true
            },
            {
              id: "key2",
              label: "Webhook API key 2",
              is: "v-text-field",
              type: "password",
              required: true,
              credential: true
            },
            {
              id: "key3",
              label: "Webhook API key 3",
              is: "v-text-field",
              type: "password",
              required: true,
              credential: true
            }
          ]
        },
        {
          text: "PAGER DUTY",
          value: "pagerduty",
          fields: []
        }
      ]
    };
  },
  methods: {
    fieldChange() {
      this.$data.optionFields = this.getOptionFields();
    },
    ifTrueField(ifss) {
      if (this.$tools.isNullOrUndefined(ifss)) return true;

      for (let key of Object.keys(ifss)) {
        let refKey = key.split(".");
        if (this.editDeviceObject[refKey[0]][refKey[1]] !== ifss[key])
          return false;
      }
      return true;
    },
    getTypeName(type) {
      for (let ii of this.serviceTypes) {
        if (ii.value === type) {
          return ii.text;
        }
      }
      return "Unknown";
    },
    addService() {
      this.$data.editDeviceObject = {
        name: null,
        active: false,
        type: null,
        config: {},
        credentials: {}
      };
      this.$data.optionFields = this.getOptionFields();
      this.$refs.sidebar.show();
    },
    viewEdit(service) {
      this.$data.editDeviceObject = JSON.parse(JSON.stringify(service));
      this.$data.editDeviceObject.credentials = {};
      this.$data.optionFields = this.getOptionFields();
      this.$refs.sidebar.show();
    },
    updateServices() {
      let self = this;
      self.$data.services = null;
      TLib.getServices(self, self.$route.params.serviceId)
        .then(x => {
          self.$data.services = x;
        })
        .catch(x => {
          self.$log.error(x);
        });
    },
    typeChange() {
      this.$data.editDeviceObject.config = {};
      this.$data.editDeviceObject.credentials = {};

      for (let ii of this.serviceTypes) {
        if (ii.value === this.editDeviceObject.type) {
          for (let field of ii.fields) {
            this.$data.editDeviceObject[
              field.credential === true ? "credentials" : "config"
            ][field.id] = field.default || null;
          }

          this.$data.optionFields = this.getOptionFields();
          return;
        }
      }
    },
    saveEditDevice() {
      this.$data.editDeviceObjectSaving = true;
      let self = this;
      TLib.setServices(
        this,
        this.$route.params.serviceId,
        this.editDeviceObject.id,
        this.editDeviceObject
      )
        .then(() => {
          self.updateServices();
          self.$refs.sidebar.hide();
          self.$data.editDeviceObjectSaving = false;
        })
        .catch(x => {
          self.$log.error(x);
          self.$data.editDeviceObjectSaving = false;
        });
    },
    getOptionFields() {
      if (
        this.$tools.isNullOrUndefined(this.editDeviceObject) ||
        this.$tools.isNullOrUndefined(this.editDeviceObject.type) ||
        this.$tools.isNullOrUndefined(this.editDeviceObject.config)
      )
        return [];

      let list = [];

      for (let ii of this.serviceTypes) {
        if (ii.value === this.editDeviceObject.type) {
          for (let field of ii.fields) {
            if (this.ifTrueField(field.if)) list.push(field);
            else if (!this.$tools.isUndefined(field.off)) {
              this.$data.editDeviceObject[
                field.credential === true ? "credentials" : "config"
              ][field.id] = field.off;
            }
          }
          //list = ii.fields;

          break;
        }
      }

      return list;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },
  beforeDestroy() {
    this.$eventBus.off(`switch-service-selected`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Networks" },
      { title: "Services" }
    ]);
    let self = this;
    this.$eventBus.on(`switch-service-selected`, () => self.updateServices());
    self.updateServices();
  }
};
</script>
